import React, { useState } from "react";
import ListView from "./ListView";
import BoardView from "./boardView.js";

function Body({ data }) {
  // Set the default tab to 'LIST'
  const [activeTab, setActiveTab] = useState("LIST");

  return (
    <>
      {/* main body */}
      <div className="sm:ml-64 p-8">
        {/* tabs section */}
        <div className="flex mb-10">
          <button
            className={`p-3 outline outline-1 mr-5 ${
              activeTab === "LIST" ? "bg-gray-200" : ""
            }`}
            onClick={() => setActiveTab("LIST")}
          >
            LIST
          </button>
          <button
            className={`p-3 outline outline-1 ${
              activeTab === "BOARD" ? "bg-gray-200" : ""
            }`}
            onClick={() => setActiveTab("BOARD")}
          >
            BOARD
          </button>
        </div>
        {/* tabs section */}

        {/* search bar  */}
        {/* <h1 className="mb-5">Search Clients</h1>
        <div className="mb-8 flex ">
          <input className="flex-grow w-4/5 mr-3 p-2 outline outline-1 rounded-sm outline-gray-300"></input>
          <button className="w-[10%] bg-indigo-500 rounded-md py-2 px-5 text-white">
            Search
          </button>
        </div> */}
        {/* search bar  */}

        {/* Table View Tabs */}
        {activeTab === "LIST" && <ListView data={data} />}
        {activeTab === "BOARD" && <BoardView data={data} />}
        {/* tabs  */}
      </div>
      {/* main body */}
    </>
  );
}

export default Body;

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Success from "./pages/successPage";
import DragAndDropList from "./pages/DragAndDropList";
import Auth from "./pages/auth";
import Dashboard from "./pages/dashboard";
import Clients from "./pages/clients";


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Auth/>}/>
        <Route path="/dnd" element={<DragAndDropList/>}/>
        <Route path="/dashboard" element={<Dashboard/>}/>
        <Route path="/clients" element={<Clients/>}/>

      </Routes>
    </Router>
  );
}

export default App;

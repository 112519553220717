import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import supabase from "./supabaseClient";

function BoardView({ data }) {
  const [items, setItems] = useState(data);

  const handleDragEnd = async (result) => {
    if (!result.destination) return;

    const reorderedItems = [...items];
    const [removed] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, removed);
    setItems(reorderedItems);

    for (let i = 0; i < reorderedItems.length; i++) {
      let { error } = await supabase
          .from('user_clients')
          .update({ sequence: i + 1 })
          .eq('id', reorderedItems[i].id);
  
      if (error) {
          console.error("Failed to update item order:", error);
          // handle error appropriately, perhaps revert the order or display an error message
      }
  }
  
};



  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="board">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {items.map((item, index) => (
              <Draggable key={item.id} draggableId={String(item.id)} index={index}>
                {(provided) => (
                  <p
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    className="bg-white p-4 border border-gray-200 rounded cursor-pointer"
                  >
                    {item.client_name}
                  </p>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default BoardView;

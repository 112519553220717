import { createClient } from "@supabase/supabase-js";
import { Auth, AuthCard } from "@supabase/auth-ui-react";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";

const supabase = createClient(
  "https://extdhrdpdmxcttevczso.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImV4dGRocmRwZG14Y3R0ZXZjenNvIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTc3MjYzNjksImV4cCI6MjAxMzMwMjM2OX0.Rzxa8ZEm0WMApb2L8V_38sXESID3rTQ1DzqfPpi71jQ"
);

function Success() {
  const [user, setUser] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    async function getUserData() {
      await supabase.auth.getUser().then((value) => {
        if (value.data?.user) {
          console.log(value.data.user);
          setUser(value.data.user);
        }
      });
    }
    getUserData();
  }, []);

  async function signOutUser() {
    const { error } = await supabase.auth.signOut();
    navigate("/");
  }

  return (
    <div className="App">
      <header className="App-header">
        {Object.keys(user).length !== 0 ? (
          <>
            <h1>Success</h1>
            <button onClick={() => signOutUser()}>Sign Out</button>
          </>
        ) : (
          <>
            <h1>User is not logged in</h1>
            <button
              onclick={() => {
                navigate("/");
              }}
            >
              Go back home!
            </button>
          </>
        )}
      </header>
    </div>
  );
}

export default Success;

import React, { useState, useEffect } from "react";
import Sidebar from "./sidebar";
import Body from "./Body";
import supabase from "./supabaseClient";

const Clients = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      let { data: fetchedData, error } = await supabase
        .from("user_clients")
        .select("*");

      console.log(fetchedData);

      if (error) {
        console.error("Error fetching data: ", error);
      } else {
        setData(fetchedData);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Sidebar />
      <Body data={data} />
    </>
  );
};

export default Clients;

import React, { useEffect } from "react";
import supabase from "./supabaseClient";
import { useNavigate } from "react-router-dom";

const Auth = () => {
  const navigate = useNavigate();

  const signInWithGoogle = async () => {
    try {
      await supabase.auth.signInWithOAuth({
        provider: "google",
      });
    } catch (error) {
      console.error("Error during Google sign-in:", error);
      alert("Error during Google sign-in. Please try again."); // or any other user notification method
    }
  };

  // Add the onAuthStateChange event listener here
  supabase.auth.onAuthStateChange((event, session) => {
    if (event === "SIGNED_IN") {
      // Handle signed in state
      console.log("User is signed in");
      console.log(session); // Log the user session details
    } else if (event === "SIGNED_OUT") {
      // Handle signed out state
      console.log("User is signed out");
    }
  });

  const signOut = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      console.log("User signed out successfully!");
    } catch (error) {
      console.error("Error during sign-out:", error);
    }
  };

  useEffect(() => {
    const checkUser = async () => {
      const { data, error } = await supabase.auth.getUser();

      if (data && data.user) {
        console.log("User is signed in");
        console.log("Username:", data.user.email); // Assuming you're using email as username
        navigate("/dashboard");
      } else {
        console.log("User is not signed in");
        if (error) {
          console.error("Error fetching user:", error);
        }
      }
    };

    checkUser();
  }, []);

  return (
    <>
      <section className="bg-gray-50 dark:bg-gray-900">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Hello Again!
              </h1>

              <div
                onClick={signInWithGoogle}
                className="flex cursor-pointer rounded-lg bg-white p-5 justify-center items-center image "
              >
                <img
                  className="w-8 mr-5"
                  src="https://user-images.githubusercontent.com/194400/70987158-4069c900-20b7-11ea-892e-8a2e1166b6b7.png"
                  alt="Description of Image"
                />
                <p className="text-lg">Sign In / Sign Up With Google</p>
              </div>
              <button onClick={signOut}>sing out</button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Auth;
import React from 'react';
import Body from './Body'
import Sidebar from './sidebar'

const Dashboard = () => {
  return (
    <div>
      <h1>This is my App</h1>
      <Sidebar /> 
      {/* <Body data={''} />  */}
    </div>
  );
  }
export default Dashboard;
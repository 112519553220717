import React, { useState } from "react";
import Logo from "../assets/Logomark.png";
import { Link } from 'react-router-dom';

function Sidebar() {
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  return (
    <>
      <aside
        id="sidebar-multi-level-sidebar"
        className="fixed top-0 left-0 z-40 w-64 h-screen transition-transform -translate-x-full sm:translate-x-0"
        aria-label="Sidebar"
      >
        <div className="h-full  overflow-y-auto bg-gray-50 flex flex-col">
          <ul className="p-3 h-full font-medium flex flex-col">
            <li>
              <a
                href="/"
                className=" flex border-b border-gray-300 rounded-none items-center py-4 px-4 text-gray-900  hover:bg-gray-100  group"
              >
                <img alt="test" src={Logo} className="w-9 transition duration-75" />
                <span className=" ml-3 text-2xl">LILY</span>
              </a>
            </li>

            <div className="h-full flex flex-col justify-between mt-6 py-2 px-1">
              <div>
                <li className=" bg-indigo-100 rounded-lg ">
                  <button
                    onClick={() => setDropdownOpen(!isDropdownOpen)}
                    type="button"
                    className=" 
                p-3
                flex items-center w-full text-base text-gray-900 transition duration-75 rounded-lg group"
                    aria-controls="dropdown-example"
                    data-collapse-toggle="dropdown-example"
                  >
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-900"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 18 21"
                    >
                      <path d="M15 12a1 1 0 0 0 .962-.726l2-7A1 1 0 0 0 17 3H3.77L3.175.745A1 1 0 0 0 2.208 0H1a1 1 0 0 0 0 2h.438l.6 2.255v.019l2 7 .746 2.986A3 3 0 1 0 9 17a2.966 2.966 0 0 0-.184-1h2.368c-.118.32-.18.659-.184 1a3 3 0 1 0 3-3H6.78l-.5-2H15Z" />
                    </svg>
                    <span className="flex-1 ml-3 text-left whitespace-nowrap">
                      Agency Home
                    </span>
                    <svg
                      className="w-3 h-3"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 10 6"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="m1 1 4 4 4-4"
                      />
                    </svg>
                  </button>

                  <ul
                    id="dropdown-example"
                    className={` space-y-2 transition-all duration-500 ease-in-out overflow-y-hidden ${
                      isDropdownOpen ? "h-auto opacity-100" : "h-0 opacity-0"
                    }`}
                  >
                    <hr className="border-b border-dotted border-indigo-300 mt-[2px] mb-[10px] max-w-[170px] mx-auto" />
                    <li>
                      <Link
                        to="/domain-setup"
                        className="flex items-center w-full text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 text-dark:white "
                      >
                        Clients
                      </Link>
                    </li>
                    <li>
                      <a
                        href="/"
                        className="mb-[10px] flex items-center w-full text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 "
                      >
                        Campaigns
                      </a>
                    </li>
                    <li>
                      <a
                        href="/"
                        className="mb-[10px] flex items-center w-full text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 "
                      >
                        Scoops
                      </a>
                    </li>
                  </ul>
                </li>
                <li className=" rounded-lg ">
                  <button
                    className=" 
                p-3
                flex items-center w-full text-base text-gray-900 transition duration-75 rounded-lg group"
                    aria-controls="dropdown-example"
                    data-collapse-toggle="dropdown-example"
                  >
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-900"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 18 21"
                    >
                      <path d="M15 12a1 1 0 0 0 .962-.726l2-7A1 1 0 0 0 17 3H3.77L3.175.745A1 1 0 0 0 2.208 0H1a1 1 0 0 0 0 2h.438l.6 2.255v.019l2 7 .746 2.986A3 3 0 1 0 9 17a2.966 2.966 0 0 0-.184-1h2.368c-.118.32-.18.659-.184 1a3 3 0 1 0 3-3H6.78l-.5-2H15Z" />
                    </svg>
                    <span className="flex-1 ml-3 text-left whitespace-nowrap">
                      Account Management
                    </span>
                  </button>
                </li>
                <li className=" rounded-lg ">
                  <button
                    className=" 
                p-3
                flex items-center w-full text-base text-gray-900 transition duration-75 rounded-lg group"
                    aria-controls="dropdown-example"
                    data-collapse-toggle="dropdown-example"
                  >
                    <svg
                      className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-900"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 18 21"
                    >
                      <path d="M15 12a1 1 0 0 0 .962-.726l2-7A1 1 0 0 0 17 3H3.77L3.175.745A1 1 0 0 0 2.208 0H1a1 1 0 0 0 0 2h.438l.6 2.255v.019l2 7 .746 2.986A3 3 0 1 0 9 17a2.966 2.966 0 0 0-.184-1h2.368c-.118.32-.18.659-.184 1a3 3 0 1 0 3-3H6.78l-.5-2H15Z" />
                    </svg>
                    <span className="flex-1 ml-3 text-left whitespace-nowrap">
                      Documents
                    </span>
                  </button>
                </li>
              </div>
            </div>
            <li className="rounded-lg">
              <button
                className="p-3 flex items-center w-full text-base text-gray-900 transition duration-75 rounded-lg group"
                //onClick={() => handleLogout()} // Add your logout logic here
                style={{
                  backgroundColor: "#ff4d4d", // Set the background color
                  color: "white", // Set the text color
                  border: "none", // Remove border
                  cursor: "pointer", // Change cursor to pointer on hover
                }}
              >
                Log Out
              </button>
            </li>
          </ul>
        </div>
      </aside>
    </>
  );
}

export default Sidebar;
